/* eslint-disable sonarjs/no-duplicate-string */
export const CONTENTFUL_TYPES = {
	VALUE: 'value',
	MEDIA_VALUE: 'mediaValue',
	RICH_TEXT_VALUE: 'richTextValue'
}

export interface ContentfulKey {
	KEY: string
	TYPE: string
	GROUP_KEY?: string
}

export const CONTENTFUL_KEYS = {
	COMMON: {
		SAFE_SECURE: {
			TITLE: {
				KEY: 'safe_secure.title',
				TYPE: CONTENTFUL_TYPES.VALUE,
				GROUP_KEY: 'safe_secure'
			},
			DESCRIPTION: {
				KEY: 'safe_secure.description',
				TYPE: CONTENTFUL_TYPES.VALUE,
				GROUP_KEY: 'safe_secure'
			},
			IMAGE: {
				KEY: 'safe_secure.image',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE,
				GROUP_KEY: 'safe_secure'
			}
		},
		PARTNERS: {
			KEY: 'partners',
			TITLE: {
				KEY: 'partners.title',
				TYPE: CONTENTFUL_TYPES.VALUE,
				GROUP_KEY: 'partners'
			},
			ITEM: {
				KEY: 'partners.item_',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE,
				GROUP_KEY: 'partners'
			},
			BUTTON: {
				KEY: 'partners.button',
				TYPE: CONTENTFUL_TYPES.VALUE,
				GROUP_KEY: 'partners'
			}
		},
		LIFE_INSURANCE: {
			KEY: 'life_insurance',
			TITLE: {
				KEY: 'life_insurance.title',
				TYPE: CONTENTFUL_TYPES.VALUE,
				GROUP_KEY: 'life_insurance'
			},
			DESCRIPTION: {
				KEY: 'life_insurance.description',
				TYPE: CONTENTFUL_TYPES.VALUE,
				GROUP_KEY: 'life_insurance'
			},
			ITEM: {
				KEY: 'life_insurance.item_',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE,
				GROUP_KEY: 'life_insurance'
			},
			BUTTON: {
				KEY: 'life_insurance.button',
				TYPE: CONTENTFUL_TYPES.VALUE,
				GROUP_KEY: 'life_insurance'
			}
		}
	},

	HOMEPAGE: {
		HERO: {
			TITLE_FIRST: {
				KEY: 'home.hero.title.first',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			TITLE_SECOND: {
				KEY: 'home.hero.title.second',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},

		PRODUCT_PICKER: {
			TEXT: {
				KEY: 'home.product_picker.text',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			BUTTON: {
				KEY: 'home.product_picker.button',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},

		SECTION_ONE: {
			TITLE: {
				KEY: 'home.section_one.title',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			ITEM_ONE: {
				TEXT: {
					KEY: 'home.section_one.item_one',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'home.section_one.item_one',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_TWO: {
				TEXT: {
					KEY: 'home.section_one.item_two',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'home.section_one.item_two',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_THREE: {
				TEXT: {
					KEY: 'home.section_one.item_three',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'home.section_one.item_three',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_FOUR: {
				TEXT: {
					KEY: 'home.section_one.item_four',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'home.section_one.item_four',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_FIVE: {
				TEXT: {
					KEY: 'home.section_one.item_five',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'home.section_one.item_five',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_SIX: {
				TEXT: {
					KEY: 'home.section_one.item_six',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'home.section_one.item_six',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_SEVEN: {
				TEXT: {
					KEY: 'home.section_one.item_seven',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'home.section_one.item_seven',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_EIGHT: {
				TEXT: {
					KEY: 'home.section_one.item_eight',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'home.section_one.item_eight',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_NINE: {
				TEXT: {
					KEY: 'home.section_one.item_nine',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'home.section_one.item_nine',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			}
		},

		SECTION_TWO: {
			TITLE: {
				KEY: 'home.section_two.title',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			ITEM_ONE: {
				TITLE: {
					KEY: 'home.step_one.title',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				TEXT: {
					KEY: 'home.step_one.text',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			ITEM_TWO: {
				TITLE: {
					KEY: 'home.step_two.title',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				TEXT: {
					KEY: 'home.step_two.text',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			ITEM_THREE: {
				TITLE: {
					KEY: 'home.step_three.title',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				TEXT: {
					KEY: 'home.step_three.text',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			BUTTON: {
				KEY: 'home.section_two.button',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},

		SECTION_THREE: {
			TITLE: {
				KEY: 'home.section_three.title',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			TEXT: {
				KEY: 'home.section_three.text',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			BUTTON: {
				KEY: 'home.section_three.button',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			IMAGE: {
				KEY: 'home.section_three.image',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			}
		},

		SECTION_FOUR: {
			TITLE: {
				KEY: 'home.section_four.title',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			DESCRIPTION: {
				KEY: 'home.section_four.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			ITEM_ONE: {
				KEY: 'home.section_four.item_one',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			},
			ITEM_TWO: {
				KEY: 'home.section_four.item_two',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			},
			ITEM_THREE: {
				KEY: 'home.section_four.item_three',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			},
			ITEM_FOUR: {
				KEY: 'home.section_four.item_four',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			},
			ITEM_FIVE: {
				KEY: 'home.section_four.item_five',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			},
			BUTTON: {
				KEY: 'home.section_four.button',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},

		BOTTOM: {
			BANNER: {
				KEY: 'home.bottom.banner',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			TITLE_FIRST: {
				KEY: 'home.bottom.title.first',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			TITLE_SECOND: {
				KEY: 'home.bottom.title.second',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			BUTTON: {
				KEY: 'home.bottom.button',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			TEXT: {
				KEY: 'home.bottom.text',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			IMAGE: {
				KEY: 'home.bottom.image',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			},
			PARTNER: {
				KEY: 'home.bottom.partner',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			LINK: {
				KEY: 'home.bottom.link',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		}
	},
	LEARN: {
		HEADER: {
			TITLE: {
				KEY: 'learn.title',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			DESCRIPTION: {
				KEY: 'learn.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},
		SORT: {
			KEY: 'learn.sort',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		DISCLAIMER: {
			KEY: 'article.disclaimer',
			TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
		},
		NO_ARTICLES: {
			BACKGROUND: {
				KEY: 'learn.no_articles',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			},
			CONTENT: {
				KEY: 'learn.no_articles',
				TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
			}
		}
	},

	PRIVACY: {
		TITLE: {
			KEY: 'privacy.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		DESCRIPTION: {
			KEY: 'privacy.description',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		SECTION: {
			KEY: 'privacy.sections'
		}
	},

	PRODUCTS: {
		PERSONAL_LOANS: {
			TITLE: {
				FIRST: {
					KEY: 'personal_loans.title_1',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				SECOND: {
					KEY: 'personal_loans.title_2',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			RELATED: {
				KEY: 'personal_loans.related',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			DESCRIPTION: {
				KEY: 'personal_loans.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},
		STUDENT_LOAN_REFINANCE: {
			TITLE: {
				FIRST: {
					KEY: 'student_loan_refinance.title_1',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				SECOND: {
					KEY: 'student_loan_refinance.title_2',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			DESCRIPTION: {
				KEY: 'student_loan_refinance.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},
		AUTO_REFINANCE: {
			TITLE: {
				FIRST: {
					KEY: 'auto_refinance.title_1',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				SECOND: {
					KEY: 'auto_refinance.title_2',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			DESCRIPTION: {
				KEY: 'auto_refinance.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},
		CREDIT_CARDS: {
			TITLE: {
				FIRST: {
					KEY: 'credit_cards.title_1',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				SECOND: {
					KEY: 'credit_cards.title_2',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			DESCRIPTION: {
				KEY: 'credit_cards.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},
		SAVINGS: {
			TITLE: {
				FIRST: {
					KEY: 'savings.title_1',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				SECOND: {
					KEY: 'savings.title_2',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			DESCRIPTION: {
				KEY: 'savings.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},
		LIFE_INSURANCE: {
			TITLE: {
				FIRST: {
					KEY: 'life.title_1',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				SECOND: {
					KEY: 'life.title_2',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			DESCRIPTION: {
				KEY: 'life.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},
		MORTGAGE: {
			TITLE: {
				FIRST: {
					KEY: 'mortgage.title_1',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				SECOND: {
					KEY: 'mortgage.title_2',
					TYPE: CONTENTFUL_TYPES.VALUE
				}
			},
			DESCRIPTION: {
				KEY: 'mortgage.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		}
	},

	CONTACT: {
		TITLE: {
			KEY: 'contact.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		SECTION_1: {
			TITLE: {
				KEY: 'contact.section_1',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			CONTENT: {
				KEY: 'contact.section_1',
				TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
			}
		},
		SECTION_2: {
			TITLE: {
				KEY: 'contact.section_2',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			CONTENT: {
				KEY: 'contact.section_2',
				TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
			}
		},
		ASIDE: {
			TITLE: {
				KEY: 'contact.aside.title',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			DESCRIPTION: {
				KEY: 'contact.aside.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			EMAIL: {
				TITLE: {
					KEY: 'contact.aside.email',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				VALUE: {
					KEY: 'contact.aside.email_value',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'contact.aside.email',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			PHONE: {
				TITLE: {
					KEY: 'contact.aside.phone',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				VALUE: {
					KEY: 'contact.aside.phone_value',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'contact.aside.phone',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			LOCATION: {
				TITLE: {
					KEY: 'contact.aside.location',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				VALUE: {
					KEY: 'contact.aside.location_value',
					TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
				},
				IMAGE: {
					KEY: 'contact.aside.location',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			}
		}
	},

	THANKS: {
		BODY: {
			KEY: 'body',
			TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
		},
		PERSONAL_LOANS: {
			IMAGE: {
				KEY: 'about.aside.item_1',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			}
		},
		SAVINGS: {
			IMAGE: {
				KEY: 'about.aside.item_2',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			}
		},
		CREDIT_CARDS: {
			IMAGE: {
				KEY: 'about.aside.item_3',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			}
		},
		LIFE_INSURANCE: {
			IMAGE: {
				KEY: 'about.aside.item_4',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			}
		}
	},

	ABOUT: {
		TITLE: {
			KEY: 'about.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		SECTION_1: {
			TITLE: {
				KEY: 'about.section_1',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			CONTENT: {
				KEY: 'about.section_1',
				TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
			}
		},
		SECTION_2: {
			TITLE: {
				KEY: 'about.section_2',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			CONTENT: {
				KEY: 'about.section_2',
				TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
			}
		},
		ASIDE: {
			TITLE: {
				KEY: 'about.aside.title',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			DESCRIPTION: {
				KEY: 'about.aside.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			ITEM_1: {
				TEXT: {
					KEY: 'about.aside.item_1',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'about.aside.item_1',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_2: {
				TEXT: {
					KEY: 'about.aside.item_2',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'about.aside.item_2',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_3: {
				TEXT: {
					KEY: 'about.aside.item_3',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'about.aside.item_3',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_4: {
				TEXT: {
					KEY: 'about.aside.item_4',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'about.aside.item_4',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			},
			ITEM_5: {
				TEXT: {
					KEY: 'about.aside.item_5',
					TYPE: CONTENTFUL_TYPES.VALUE
				},
				IMAGE: {
					KEY: 'about.aside.item_5',
					TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
				}
			}
		}
	},

	FAQ: {
		SECTION_KEY: 'faq.section_',
		SECTION_TITLE_KEY: '.title',
		TITLE: {
			KEY: 'faq.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		BOTTOM_SECTION: {
			KEY: 'faq.bottom_section',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		ASIDE: {
			TITLE: {
				KEY: 'faq.aside.title',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			DESCRIPTION: {
				KEY: 'faq.aside.description',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},
		EMAIL: {
			TITLE: {
				KEY: 'contact.aside.email',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			IMAGE: {
				KEY: 'contact.aside.email',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			}
		},
		PHONE: {
			TITLE: {
				KEY: 'contact.aside.phone',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			IMAGE: {
				KEY: 'contact.aside.phone',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			}
		}
	},

	TERMS: {
		TITLE: {
			KEY: 'tos.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		DESCRIPTION: {
			KEY: 'tos.description',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		SECTION: {
			KEY: 'tos.sections'
		}
	},

	ARTICLE: {
		DISCLAIMER: {
			KEY: 'article.disclaimer',
			TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
		}
	},

	LOANS: {
		TITLE: {
			KEY: 'loans.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		DESCRIPTION: {
			KEY: 'loans.description',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		LINK: {
			KEY: 'loans.link',
			TYPE: CONTENTFUL_TYPES.VALUE
		}
	},
	INSURANCE: {
		TITLE: {
			KEY: 'insurance.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		DESCRIPTION: {
			KEY: 'insurance.description',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		CONTENT: {
			TITLE: {
				KEY: 'insurance_content.title',
				TYPE: CONTENTFUL_TYPES.VALUE
			}
		},
		ITEM_ONE: {
			TEXT: {
				KEY: 'insurance.item_one',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			IMAGE: {
				KEY: 'insurance.item_one',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			}
		},
		ITEM_TWO: {
			TEXT: {
				KEY: 'insurance.item_two',
				TYPE: CONTENTFUL_TYPES.VALUE
			},
			IMAGE: {
				KEY: 'insurance.item_two',
				TYPE: CONTENTFUL_TYPES.MEDIA_VALUE
			}
		},
		LINK: {
			KEY: 'insurance.link',
			TYPE: CONTENTFUL_TYPES.VALUE
		}
	},

	PROVIDERS: {
		TITLE: {
			KEY: 'providers.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		DISCLAIMER: {
			KEY: 'providers.disclaimer',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		LIST: {
			KEY: 'providers.list',
			TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
		}
	},

	CREDIT_AUTHORIZATION: {
		TITLE: {
			KEY: 'credit_authorization.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		LIST: {
			KEY: 'credit_authorization.content',
			TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
		}
	},
	ELECTRONIC_CONSENT: {
		CONTENT: {
			KEY: 'electronic_consent.content',
			TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
		},
		TITLE: {
			KEY: 'electronic_consent.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		}
	},
	ERROR: {
		TITLE: {
			KEY: 'error.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		SUBTITLE: {
			KEY: 'error.subtitle',
			TYPE: CONTENTFUL_TYPES.VALUE
		}
	},
	DISCLOSURE: {
		CONTENT: {
			KEY: 'disclosure.content',
			TYPE: CONTENTFUL_TYPES.RICH_TEXT_VALUE
		}
	},
	DASHBOARD: {
		TITLE: {
			KEY: 'dashboard.title',
			TYPE: CONTENTFUL_TYPES.VALUE
		},
		SUBTITLE: {
			KEY: 'dashboard.subtitle',
			TYPE: CONTENTFUL_TYPES.VALUE
		}
	}
}
